import Salem_black from '../Images/Salem_black.png'
import { Button, AppBar, Toolbar, Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    color: 'black',
    '&:hover': {
        backgroundColor: '#1996a8',
        borderColor: '#0062cc',
        color: 'white',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(6,34,48,.5)',
    },
}))

const StyledHeader = styled(AppBar)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: 'white',
    width: '100%',
    position: 'sticky',
}))

const Nav = () => {
    return (
        <StyledHeader>
            <Toolbar variant="dense">
                <Link to="https://salemcyber.com" target="blank">
                    <img src={Salem_black} alt="company logo" width={182} />
                </Link>
                <Box sx={{marginLeft: 'auto', display: 'flex'}}>
                    <StyledButton href="/about"> New to Studio?</StyledButton>
                    <StyledButton href="https://www.surveymonkey.com/r/H2BJHZH" target='blank'> Feedback Survey</StyledButton>
                    <StyledButton href="/"> Investigation Guides</StyledButton>
                    
                </Box>
            </Toolbar>
        </StyledHeader>
    )
}

export default Nav
