import React from 'react';

const ProbabilityGauge = ({
    percentage = 0.75,
    colorLow = 'green',
    colorMedium = 'orange',
    colorHigh = 'red',
    colorBackground = '#eee',
    colorText = 'white',
    imageUrl,
    startAngle = -140, // Adjusted for better positioning
    endAngle = 140      // Adjusted for better positioning
  }) => {
    // Fixed size values for consistent rendering
    const radius = 100;
    const strokeWidth = 8;
    const padding = 20;  // Padding to prevent cutoff
    
    // Total SVG size including padding
    const size = (radius + padding) * 2;
    
    // Convert angles to radians
    const startAngleRad = (startAngle - 90) * (Math.PI / 180);
    const endAngleRad = (endAngle - 90) * (Math.PI / 180);

    // Set Color
    const color = percentage < .35 ? colorLow : (percentage < .7 ? colorMedium : colorHigh)
    
    // Calculate arc path
    const getArcPath = (radius, startAngle, endAngle) => {
      const start = {
        x: radius + radius * Math.cos(startAngleRad),
        y: radius + radius * Math.sin(startAngleRad)
      };
      const end = {
        x: radius + radius * Math.cos(endAngleRad),
        y: radius + radius * Math.sin(endAngleRad)
      };
      
      const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
      
      return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}`;
    };
  
    // Calculate the actual radius for the arc (accounting for stroke width)
    const actualRadius = radius - strokeWidth / 2;
    
    // Calculate arc length and progress
    const arcLength = (endAngleRad - startAngleRad) * actualRadius;
    const progress = percentage * arcLength;
    
    // Calculate image size and position (increased size)
    const imageSize = radius * 1; // Increased from 0.5 to 0.8
    const imageX = radius + padding - imageSize / 2;
    const imageY = radius + padding - imageSize / 2 - (radius * 0.15); // Moved up by 15% of radius
  
    // Calculate text position
    const textY = imageY + imageSize + 20; // 20px below image
    const fontSize = radius * 0.25; // Adjusted font size
  
    return (
      <svg 
        viewBox={`0 0 ${size} ${size}`} // Added extra height for text
        width={size} 
        height={size}
        style={{ display: 'block' }}
      >
        <g transform={`translate(${padding}, ${padding})`}>
          {/* Background arc */}
          <path
            d={getArcPath(actualRadius, startAngle, endAngle)}
            stroke={colorBackground}
            strokeWidth={strokeWidth}
            fill="none"
            transform={`translate(${radius}, ${radius}) rotate(0) translate(-${radius}, -${radius})`}
          />
          
          {/* Progress arc */}
          <path
            d={getArcPath(actualRadius, startAngle, endAngle)}
            stroke={color}
            strokeWidth={strokeWidth}
            fill="none"
            strokeDasharray={`${progress} ${arcLength}`}
            style={{ transition: 'stroke-dasharray 0.3s' }}
            transform={`translate(${radius}, ${radius}) rotate(0) translate(-${radius}, -${radius})`}
          />
        </g>
        
        {/* Centered image */}
        {imageUrl && (
          <image
            href={imageUrl}
            x={imageX}
            y={imageY}
            width={imageSize}
            height={imageSize}
            preserveAspectRatio="xMidYMid meet"
          />
        )}
        
        {/* Text below image */}
        <text
          x={size / 2}
          y={textY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{ 
            fontSize: fontSize,
            fontWeight: 'bold',
            fill: {colorText}
          }}>
          {`${Math.round(percentage * 100)}%`}
        </text>
      </svg>
    );
  };

export default ProbabilityGauge;