import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const Footer = () => {
    const today = new Date();
    const StyledFooter = styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
    }));
    const footerTextStyle = {
        textAlign: "center",
        fontSize: "14px",
    }
    return (
        <StyledFooter>
            <div className={footerTextStyle}>Salem Cyber &copy; {today.getFullYear()}.  All Rights Reserved</div>
        </StyledFooter>
    )
}

export default Footer
