import React from 'react'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Unauthorized from './components/Unauthorized'
import NotFound from './components/NotFound'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import { Box } from '@mui/material'
import Playbook from './components/playbook'
import Terms from './components/Terms'
import AppBackground from './components/Background'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import About from './components/about'

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */

const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    }
  });

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AppBackground />
            <Box
                className="App"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    fontFamily: 'Montserrat, sans-serif;'
                }}
            >
                <Nav />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/guides/:id" element={<Playbook />} />
                    <Route path="/about" element={<About />}/>
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="*" exact={true} element={<NotFound />} />
                </Routes>
                <Footer />
            </Box>
        </ThemeProvider>
    )
}

export default App
