import React from 'react'
import { Box, Container, Paper, Typography } from '@mui/material'

const body = () => {
    return (
        <>
            <Typography variant='h4' sx={{ mb: 2}}>Terms of Service</Typography>
            <Typography variant='body1' sx={{ mb: 2}}>Last Modified: January 14, 2025</Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            This License Agreement (this "<Typography variant='span' sx={{ fontWeight: 'bold'}}>Agreement</Typography>") is a binding contract between you ("<Typography variant='span' sx={{ fontWeight: 'bold'}}>you</Typography>" or "<Typography variant='span' sx={{ fontWeight: 'bold'}}>your</Typography>") and Salem, Inc. ("<Typography variant='span' sx={{ fontWeight: 'bold'}}>Company</Typography>," "<Typography variant='span' sx={{ fontWeight: 'bold'}}>we</Typography>," or "<Typography variant='span' sx={{ fontWeight: 'bold'}}>us</Typography>"). This Agreement governs your access to, use of and testing of the software application related to this click through (beta version) (the "<Typography variant='span' sx={{ fontWeight: 'bold'}}>Software</Typography>"). 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            By clicking a button denoting acceptance of this Agreement, or by implementing, downloading or otherwise accessing the Software, You agree to be legally bound by the terms of this Agreement. If You are entering into this Agreement on behalf of an organization, You represent You have the authority to bind Your organization to this Agreement. If You do not have that authority, or if You do not agree with the terms of this Agreement, You may not use the Software. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            1. <Typography variant='span' sx={{ textDecoration: 'underline'}}>License Grant</Typography>. Subject to and conditioned on your compliance with all terms and conditions set forth in this Agreement we hereby grant you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license during the term of the Agreement to use the Software solely for your internal business purposes in testing and evaluating the Software. You agree that that You shall not use Software for any production or commercial purpose, in any production environment or for the processing of live data.   You acknowledge that there are no implied licenses granted under this Agreement. We reserve all rights that are not expressly granted. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            2.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Use Restrictions</Typography>. You shall not translate, adapt, reverse-engineer, decompile, create derivative works, disassemble or modify Software, deliverables or other materials provided in conjunction with this evaluation in whole or in part for any purpose. You shall comply with all applicable laws and regulations relating to the use of Software. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            3.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>No Support; Updates</Typography>. This Agreement does not entitle you to any support for the Software. You acknowledge that we may update or modify the Software from time to time and at our sole discretion (in each instance, an "Update"), and may require you to obtain and use the most recent version of the Software. Your continued use of the Software following an Update constitutes binding acceptance of the Update. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            4.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>No Fees</Typography>. You acknowledge and agree that no license fees or other payments will be due under this Agreement in exchange for the rights granted under this Agreement. You acknowledge and agree that this fee arrangement is made in consideration of the mutual covenants set forth in this agreement, including, without limitation, the disclaimers, exclusions, and limitations of liability set forth herein. Notwithstanding the foregoing, we reserve the right to start charging for access to and use of the Software at any time. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            5.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Collection and Use of Your Information</Typography>. We may collect certain information about you or any of your employees, contractors, or agents. By accessing, using, and providing information to or through the Software or sign-up, you consent to all actions taken by us with respect to your information in compliance with the then-current version of our privacy policy and data protection requirements, available at https://www.salemcyber.com/privacy. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            6.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Intellectual Property Ownership; Feedback</Typography>. You acknowledge that, as between you and us, (a) we own all right, title, and interest, including all intellectual property rights, in and to the Software and (b) you own all right, title, and interest, including all intellectual property rights, in and to your information. You will promptly notify us if you become aware of any infringement of any intellectual property rights in the Software and will fully cooperate with us, in any legal action taken by us to enforce our intellectual property rights. If you or any of your employees, contractors, and agents sends or transmits any communications or materials to us by mail, email, telephone, or otherwise, suggesting or recommending changes to the Software including without limitation, new features or functionality relating thereto, or any comments, questions, suggestions, or the like ("Feedback"), all such Feedback is and will be treated as non-confidential. You hereby assign to us on your behalf, and on behalf of your employees, contractors, and agents, all right, title, and interest in, and we are free to use, without any attribution or compensation to you or any third party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although we are not required to use any Feedback.
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            7.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Confidentiality</Typography>. The Software (including, without limitation, all improvement, derivatives, modifications and the like) constitutes Company’s confidential information (“Confidential Information”).  You hereby agree (i) to hold the Confidential Information in confidence and to take reasonable precautions to protect such Confidential Information (including, without limitation, all precautions You employ with respect to its own confidential materials), (ii) not to divulge any Confidential Information to any third person (except with Our prior written consent), and (iii) not to use any Confidential Information except for the purposes set forth in this Agreement.  You acknowledge and agree that due to the unique nature of the Company’s Confidential Information, there can be no adequate remedy at law for any breach of its obligations hereunder and therefore, that upon any such breach or any threat thereof, Company shall be entitled to appropriate equitable relief in addition to whatever remedies it might have at law.
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            8.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Disclaimer of Warranties</Typography>. THE SOFTWARE IS PROVIDED "AS IS" AND COMPANY SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. COMPANY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. COMPANY MAKES NO WARRANTY OF ANY KIND THAT THE SOFTWARE, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY OF YOUR OR ANY THIRD PARTY'S SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            9.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Indemnification</Typography>. You agree to indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to (a) your use or misuse of the Software, and (b) your breach of this Agreement. In the event we seek indemnification or defense from you under this provision, we will promptly notify you in writing of the claim(s) brought against us for which we seek indemnification or defense. We reserve the right, at our option and in our sole discretion, to assume full control of the defense of claims with legal counsel of our choice. You may not enter into any third-party agreement that would, in any manner whatsoever, constitute an admission of fault by us or bind us in any manner, without our prior written consent. In the event we assume control of the defense of such claim, we will not settle any such claim requiring payment from you without your prior written approval. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            10.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Limitations of Liability</Typography>. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY UNDER ANY TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER LEGAL OR EQUITABLE THEORY FOR (a) ANY LOST PROFITS, LOST OR CORRUPTED DATA, COMPUTER FAILURE OR MALFUNCTION, INTERRUPTION OF BUSINESS, OR OTHER SPECIAL, INDIRECT, DIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE; OR (b) ANY DAMAGES, IN THE AGGREGATE, IN EXCESS OF FIFTY DOLLARS EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES AND WHETHER OR NOT SUCH LOSS OR DAMAGES ARE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANY CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE BROUGHT WITHIN ONE (1) YEAR AFTER THE OCCURRENCE OF THE EVENT GIVING RISE TO SUCH CLAIM.
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            11.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Term and Termination</Typography>. The term of this Agreement commences when you acknowledge your acceptance of this Agreement by clicking the button denoting acceptance, or by implementing, downloading or otherwise accessing the Software, and will continue in effect until terminated as set forth in this Section. We may immediately terminate or suspend this Agreement, any rights granted herein, and/or your licenses under this Agreement, in our sole discretion at any time and for any reason, by providing notice to you or revoking access to the Software. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement. Any terms that by their nature are intended to continue beyond the termination or expiration of this Agreement will survive termination. Termination will not limit any of Company's rights or remedies at law or in equity.
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            12.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Modifications</Typography>. You acknowledge and agree that we have the right, in our sole discretion, to modify this Agreement from time to time. Such modifications will be effective immediately. Your continued use of the Software constitutes your acceptance of the modified terms. It is your responsibility to review this Agreement periodically for any modifications.. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            13.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Governing Law and Jurisdiction</Typography>. This agreement is governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice of conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of Delaware. Except as otherwise set forth herein, any legal suit, action, or proceeding arising out of or related to this agreement or the licenses granted hereunder will be instituted exclusively in the federal courts of the United States or the courts of the State of Delaware, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            14.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Severability</Typography>. If any provision of this Agreement is found to be illegal or unenforceable, such portion will be deemed to be restated to reflect as nearly as possible the original intentions of the parties in accordance with applicable law, and the remainder of this Agreement will remain in full force and effect. 
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            15.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Arbitration</Typography>. At our sole discretion, we may require you to submit any disputes arising under this Agreement, including disputes arising from or concerning its interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Delaware law.
            </Typography>
            <Typography variant='body1' sx={{ mb: 2}}>
            16.	<Typography variant='span' sx={{ textDecoration: 'underline'}}>Miscellaneous</Typography>. This Agreement constitutes the entire agreement and understanding between the parties hereto with respect to the subject matter hereof and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. Notwithstanding the foregoing, you hereby consent to receiving electronic communications from us. These electronic communications may include notices about applicable fees and charges, transactional information, and other information concerning or related to the Software or any other products. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing. The invalidity, illegality, or unenforceability of any provision herein does not affect any other provision herein or the validity, legality, or enforceability of such provision in any other jurisdiction. Any failure to act by us with respect to a breach of this Agreement by you or others does not constitute a waiver and will not limit our rights with respect to such breach or any subsequent breaches. This Agreement is personal to you and may not be assigned or transferred for any reason whatsoever without our prior written consent and any action or conduct in violation of the foregoing will be void and without effect. We expressly reserve the right to assign this Agreement and to delegate any of its obligations hereunder.
            </Typography>
        </>
    )
}

const Terms = () => {
    return (
        <Container>
            <Paper sx={{ bgcolor: '#00243A' }}>
                <Box sx={{ mb: 4, p: 2,  bgcolor: 'white' }}>{body()}</Box>
            </Paper>
        </Container>
    )
}

export default Terms
