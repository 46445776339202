import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Modal,
    Box,
    Typography,
    Chip,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Tooltip,
    IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import GenerateReportModal from './GenerateReportModal'
import ProbabilityGauge from './threatLikelihood'
import salemIcon from '../Images/Salem_chat@500.png'

const Playbook = () => {
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [activeStep, setActiveStep] = useState(0)
    const [selectedChips, setSelectedChips] = useState({})
    const [userInputs, setUserInputs] = useState({})
    const [prediction, setPrediction] = useState(null)
    const [neededContext, setNeededContext] = useState(null)
    const [pdfModalOpen, setPdfModalOpen] = useState(false)
    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [pdfBlob, setPdfBlob] = useState(null)
    const [pdfError, setPdfError] = useState(null)
    const [cardNotes, setCardNotes] = useState({})
    const [awake, setAwake] = useState(null)
    const [playbook, setPlaybook] = useState({})
    const [loading, setLoading] = useState(true)
    const [comment, setComment] = useState(null)
    const [tosAccepted, setTosAccepted] = useState(false)

    const keyFields = [
        'action',
        'related_actions',
        'account',
        'src_account',
        'src',
        'dest',
        'program',
        'parent_program',
        'data',
    ]

    const columnOneTip =
        "To start investigating a security alert, you'll need to collect relevant data as described in the table below.  This data may already be in the security alert, or you may need to search other systems in your environment."
    const columnTwoTip =
        'Enter the answer(s) to the prompt in the "Do This" section. This data will be included in a summary report generated at the end of the investigation. Data entered in this column is not recorded by Salem Studio.'
    const columnThreeTip =
        'Salem Studio needs to understand what you know about this alert activity and the objects and entities involved.   Answering investigation questions allows Salem to recommend a threat likelihood. More complete information improves the accuracy of the threat assessment.'

    useEffect(() => {
        const loadPlaybook = async () => {
            try {
                const data = await import(`../data/playbooks/${id}.json`)
                setPlaybook(data.default)
                setLoading(false)
                console.log(`playbook {id} loaded`)
            } catch (error) {
                console.log('failed to load playbook')
            }
        }
        const wakeup = async () => {
            if (!awake) {
                await axios({
                    method: 'get',
                    url: '/api/hi',
                })
                setAwake(1)
            }
        }
        if (id) {
            loadPlaybook()
            wakeup()
        }
    })

    const headline = () => {
        return (
            <Paper sx={{ bgcolor: '#00243A' }}>
                <Box sx={{ mb: 4, p: 2 }}>
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'center',
                            mb: 2,
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                    >
                        {playbook.title}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', mb: 4, color: 'white' }}
                    >
                        {playbook.subtitle}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        {/* False Positive Box */}
                        <Paper
                            elevation={0}
                            sx={{ flex: 1, bgcolor: '#00A3BF' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: '#00A3BF',
                                        color: 'white',
                                        p: 1.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Common False Positive Factors
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        p: 2,
                                        height: 'auto',
                                        border: 4,
                                        borderColor: '#00A3BF',
                                        borderTop: 0,
                                        bgcolor: '#00243A',
                                        color: 'white',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            m: 0,
                                            pl: 2,
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        <Typography>
                                            {playbook.falsePositives}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                        {/* Attacker Behavior Box */}
                        <Paper
                            elevation={0}
                            sx={{ flex: 1, bgcolor: '#D3510C' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: '#D3510C',
                                        color: 'white',
                                        p: 1.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Attacker Behavior
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        p: 2,
                                        border: 4,
                                        borderColor: '#D3510C',
                                        borderTop: 0,
                                        bgcolor: '#00243A',
                                        color: 'white',
                                    }}
                                >
                                    <Typography>{playbook.behavior}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Paper>
        )
    }

    const TosCheckbox = () => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={tosAccepted}
                    onChange={(e) => setTosAccepted(e.target.checked)}
                    sx={{
                        color: '#1996a8',
                        '&.Mui-checked': {
                            color: '#1996a8',
                        },
                    }}
                />
            }
            label={
                <Typography variant="body2" sx={{ color: 'white' }}>
                    I accept the{' '}
                    <Link
                        href="/terms"
                        target="_blank"
                        sx={{
                            color: '#1996a8',
                            '&:hover': {
                                color: '#147885',
                            },
                        }}
                    >
                        terms of service
                    </Link>
                </Typography>
            }
        />
    )

    const analysis = () => {
        return (
            <Paper sx={{ mt: 3, p: 2, bgcolor: '#F0FAFA' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexShrink: 0 }}>
                        <ProbabilityGauge
                            percentage={prediction / 100}
                            radius={100}
                            strokeWidth={10}
                            colorLow="#134E55"
                            colorMedium="#D3510C"
                            colorHigh="#C00000"
                            colorText="black"
                            colorBackground="#d3f3f8"
                            imageUrl={salemIcon}
                            startAngle={-140}
                            endAngle={140}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', // This centers content vertically
                            gap: 2,
                            pl: 4, // Add some padding to the left for spacing from the gauge
                        }}
                    >
                        <Typography variant="body1">
                            based on the parameters you selected and analysis of
                            similar events
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Typography variant="h6" sx={{ color: 'black' }}>
                                The threat likelihood of this event is:{' '}
                                <Typography
                                    component="span"
                                    variant="h5"
                                    sx={{
                                        fontWeight: 'bold',
                                        color:
                                            prediction < 35
                                                ? '#134E55'
                                                : prediction < 70
                                                ? '#D3510C'
                                                : '#C00000',
                                    }}
                                >
                                    {prediction < 35
                                        ? 'LOW'
                                        : prediction < 70
                                        ? 'MODERATE'
                                        : 'HIGH'}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {neededContext ? (
                    <Box
                        sx={{
                            pt: 8,
                            display: 'flex',
                            gap: 3,
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        {/* Attacker Behavior Box */}
                        <Paper
                            elevation={0}
                            sx={{ flex: 1, bgcolor: '#D3510C' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: '#00A3BF',
                                        color: 'white',
                                        p: 1.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Top Risk Decelerators
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        p: 2,
                                        border: 4,
                                        borderColor: '#00A3BF',
                                        borderTop: 0,
                                        bgcolor: '#00243A',
                                        color: 'white',
                                    }}
                                >
                                    {keyFields.map((i) => {
                                        const contextValues =
                                            neededContext.false_positive
                                                .map((v) => {
                                                    if (v.startsWith(`${i}.`)) {
                                                        const v2 = v.split('.')
                                                        return v2[1].replaceAll(
                                                            '_',
                                                            ' ',
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                .filter((v) => v !== null)
                                        if (contextValues.length === 0) {
                                            return <></>
                                        }
                                        return (
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        pt: 2,
                                                    }}
                                                >
                                                    {i.replaceAll('_', ' ')}
                                                </Typography>
                                                <Typography>
                                                    {contextValues.join(', ')}
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                flex: 1,
                                bgcolor: '#00243A',
                                borderColor: '#D3510C',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: '#D3510C',
                                        color: 'white',
                                        p: 1.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Top Risk Accelerators
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        p: 2,
                                        border: 4,
                                        borderColor: '#D3510C',
                                        borderTop: 0,
                                        bgcolor: '#00243A',
                                        color: 'white',
                                    }}
                                >
                                    {keyFields.map((i) => {
                                        const contextValues =
                                            neededContext.threat
                                                .map((v) => {
                                                    if (v.startsWith(`${i}.`)) {
                                                        const v2 = v.split('.')
                                                        return v2[1].replaceAll(
                                                            '_',
                                                            ' ',
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                .filter((v) => v !== null)
                                        if (contextValues.length === 0) {
                                            return <></>
                                        }
                                        return (
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        pt: 2,
                                                    }}
                                                >
                                                    {i.replaceAll('_', ' ')}
                                                </Typography>
                                                <Typography>
                                                    {contextValues.join(', ')}
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            pt: 4,
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: '#D3510C',
                                color: 'white',
                                pt: 2,
                                pr: 1,
                                pl: 1,
                                textAlign: 'left',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            <Typography
                                variant="span"
                                sx={{ fontSize: '1rem' }}
                            >
                                {'Response\nRecommendations'}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                p: 2,
                                bgcolor: '#00243A',
                                border: 4,
                                borderColor: '#D3510C',
                                color: 'white',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            <Typography variant="body1" component="p">
                                {prediction > 50
                                    ? playbook.recommendations.high
                                    : playbook.recommendations.low}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box sx={{ pt: 4 }}>
                    <TextField
                        multiline
                        minRows={2}
                        maxRows={6}
                        fullWidth
                        label="Investigator's Comments"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        variant="outlined"
                    />
                </Box>
            </Paper>
        )
    }

    // Your existing handlers...
    const handleRowClick = (row) => {
        setSelectedRow(row)
        setActiveStep(0)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelectedRow(null)
    }

    const handleNext = () => {
        setActiveStep((prev) =>
            Math.min(prev + 1, selectedRow?.cards.length - 1),
        )
    }

    const handleBack = () => {
        setActiveStep((prev) => Math.max(prev - 1, 0))
    }

    const handleChipClick = (chipText, rowId, category) => {
        setPrediction(null) // Reset prediction when chips change
        setNeededContext(null)
        const chipContext = chipText.includes('.')
            ? chipText
            : `${category}.${chipText}`
        setSelectedChips((prev) => {
            const rowChips = new Set(prev[rowId] || [])
            if (rowChips.has(chipContext)) {
                rowChips.delete(chipContext)
            } else {
                rowChips.add(chipContext)
            }
            return {
                ...prev,
                [rowId]: Array.from(rowChips),
            }
        })
    }

    const hasSelectedChips = (minChipsRequired = 3) => {
        const totalSelectedChips = Object.values(selectedChips).reduce(
            (total, chips) => total + (chips?.length || 0),
            0,
        )
        return totalSelectedChips >= minChipsRequired
    }

    const handleTextChange = (rowId, value) => {
        setUserInputs((prev) => ({
            ...prev,
            [rowId]: value,
        }))
    }

    const clearAll = () => {
        setSelectedChips({})
        setUserInputs({})
        setPrediction(null)
        setNeededContext(null)
        setCardNotes({})
        setComment(null)
    }

    const handleSubmit = async () => {
        // Create context object by grouping chips by category
        const context = [...playbook.defaultContext]

        // Iterate through each row with selected chips
        Object.entries(selectedChips).forEach(([rowId, chips]) => {
            if (chips && chips.length > 0) {
                context.push(...chips)
            }
        })

        // Make the API call with the constructed context
        let res = await post('predict', {
            alert: {
                context: context,
            },
        })
        let new_prediction = Math.floor(res.prediction[0] * 100)
        if (new_prediction < 1) {
            new_prediction = 1
        }
        setPrediction(new_prediction)
        if (new_prediction >= 35 && new_prediction < 70) {
            setNeededContext(res.top_movers)
        }
    }

    const generatePDF = async () => {
        setPdfModalOpen(true)
        setGeneratingPdf(true)
        setPdfBlob(null)
        setPdfError(null)

        try {
            const response = await axios({
                method: 'post',
                url: '/api/generate_report',
                responseType: 'blob',
                data: {
                    playbook,
                    selectedChips,
                    userInputs,
                    cardNotes,
                    prediction,
                    comment,
                },
            })

            // Store the blob for later download
            setPdfBlob(new Blob([response.data], { type: 'application/pdf' }))
            setGeneratingPdf(false)
        } catch (error) {
            console.error('Error generating PDF:', error)
            setPdfError(
                'An error occurred while generating the report. Please try again.',
            )
            setGeneratingPdf(false)
        }
    }

    const handlePdfDownload = () => {
        if (pdfBlob) {
            const url = window.URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = url
            link.download = `analysis-report-${new Date().toISOString()}.pdf`
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
            setPdfModalOpen(false)
            setPdfBlob(null)
        }
    }

    // Function to handle modal close
    const handleModalClose = () => {
        if (!generatingPdf) {
            setPdfModalOpen(false)
            setPdfBlob(null)
        }
    }

    const post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        })
        let body = await res.data
        return body
    }

    // Modal style
    const modalStyle = {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: '#031017',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        borderColor: 'white',
        border: 4,
        minWidth: '50vw',
        maxWidth: '90vw',
        maxHeight: '90vh',
        overflow: 'auto',
    }

    if (loading) {
        return <></>
    }

    return (
        <Container sx={{ pt: 4, pb: 16, backgroundColor: '#031017' }}>
            {headline()}
            <TableContainer
                component={Paper}
                className="w-full"
                sx={{ p: 2, bgcolor: '#00243A' }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="25%">
                                <Tooltip placement="top" title={columnOneTip}>
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Do This <Typography component='span'> &#9432;</Typography>
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell width="35%">
                                <Tooltip placement="top" title={columnTwoTip}>
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        What did you Find? <Typography component='span'> &#9432;</Typography>
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell width="40%">
                                <Tooltip placement="top" title={columnThreeTip}>
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Investigation Context <Typography component='span'> &#9432;</Typography>
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {playbook.tableData.map((row) => (
                            <TableRow
                                key={row.id}
                                hover
                                className="cursor-pointer"
                            >
                                <TableCell
                                    onClick={() => handleRowClick(row)}
                                    sx={{ color: 'white', fontWeight: 'bold' }}
                                >
                                    {row.col1}
                                </TableCell>
                                <TableCell onClick={(e) => e.stopPropagation()}>
                                    <TextField
                                        multiline
                                        minRows={2}
                                        maxRows={6}
                                        fullWidth
                                        label={row.col2}
                                        value={userInputs[row.id] || ''}
                                        onChange={(e) =>
                                            handleTextChange(
                                                row.id,
                                                e.target.value,
                                            )
                                        }
                                        variant="standard"
                                        sx={{
                                            '& .MuiInput-input': {
                                                color: '#B5E7ED',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white',
                                            },
                                            '& .MuiInputLabel-root.Mui-focused':
                                                {
                                                    color: 'white',
                                                },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#B5E7ED',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={() => handleRowClick(row)}
                                    sx={{
                                        width: '40%',
                                        maxWidth: '40%',
                                        minWidth: '40%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '8px',
                                            flexWrap: 'wrap',
                                            minHeight: '50px',
                                        }}
                                    >
                                        {(() => {
                                            // Combine all arrays that start with this base ID
                                            const combinedChips =
                                                Object.entries(selectedChips)
                                                    .filter(([key]) =>
                                                        key.startsWith(
                                                            `${row.id}-`,
                                                        ),
                                                    )
                                                    .flatMap(
                                                        ([_, chips]) => chips,
                                                    )

                                            return combinedChips.length > 0 ? (
                                                combinedChips.map(
                                                    (chip, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={
                                                                chip
                                                                    .replaceAll(
                                                                        '_',
                                                                        ' ',
                                                                    )
                                                                    .split(
                                                                        '.',
                                                                    )[1]
                                                            }
                                                            sx={{
                                                                backgroundColor:
                                                                    '#1996a8',
                                                                color: 'white',
                                                                fontSize:
                                                                    '0.9rem',
                                                                height: '32px',
                                                            }}
                                                            size="medium"
                                                        />
                                                    ),
                                                )
                                            ) : (
                                                <Typography
                                                    color="white"
                                                    sx={{ padding: '8px' }}
                                                >
                                                    Click for investigation
                                                    questions
                                                </Typography>
                                            )
                                        })()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {prediction ? analysis() : <></>}

            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {prediction ? (
                    <>
                        <Button
                            variant="contained"
                            onClick={generatePDF}
                            sx={{
                                bgcolor: '#1996a8',
                                '&:hover': {
                                    bgcolor: '#147885',
                                },
                            }}
                        >
                            Generate Report
                        </Button>
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <TosCheckbox />
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!hasSelectedChips() || !tosAccepted}
                            sx={{
                                bgcolor: '#1996a8',
                                fontWeight: 'bold',
                                '&:hover': {
                                    bgcolor: '#147885',
                                },
                                '&.Mui-disabled': {
                                    bgcolor: '#e0e0e0',
                                },
                            }}
                        >
                            Analyze
                        </Button>
                    </Box>
                )}
                <Button
                    variant="contained"
                    onClick={clearAll}
                    sx={{
                        bgcolor: '#1996a8',
                        '&:hover': {
                            bgcolor: '#147885',
                        },
                        '&.Mui-disabled': {
                            bgcolor: '#e0e0e0',
                        },
                    }}
                >
                    Clear
                </Button>
            </Box>
            <Box sx={{ p: 1, mt: 8 }}>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'white',
                        textAlign: 'center',
                    }}
                >
                    Looking for automated alert triage? Check out{' '}
                    <Link
                        href="https://salemcyber.com/solution"
                        target="_blank"
                        sx={{
                            color: '#91DBE5',
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'none',
                                color: '#F8AD86',
                            },
                        }}
                    >
                        Salem, AI Cyber Analyst
                    </Link>
                </Typography>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="investigation-questions"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={modalStyle}>
                    {selectedRow && (
                        <div>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: '5px',
                                    top: '5px',
                                    color: 'grey.500',
                                    '&:hover': {
                                        color: 'grey.700',
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h4"
                                component="h2"
                                sx={{ pb: 2 }}
                            >
                                Alert Investigation Questions
                                <Typography
                                    variant="h6"
                                    component="span"
                                    sx={{ pb: 2 }}
                                >
                                    {' '}
                                    ({activeStep + 1} of{' '}
                                    {selectedRow.cards.length})
                                </Typography>
                            </Typography>
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2, fontWeight: 'bold' }}
                            >
                                {selectedRow.cards[activeStep].header}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="h3"
                                sx={{ mb: 2 }}
                            >
                                (select all that apply)
                            </Typography>

                            <div
                                style={{
                                    display: 'flex',
                                    gap: '8px',
                                    flexWrap: 'wrap',
                                    marginBottom: '16px',
                                }}
                            >
                                {selectedRow.cards[activeStep].chips.map(
                                    (chip, index) => (
                                        <Chip
                                            key={index}
                                            label={chip
                                                .replaceAll('_', ' ')
                                                .split('.')
                                                .pop()}
                                            onClick={() =>
                                                handleChipClick(
                                                    chip,
                                                    `${selectedRow.id}-${activeStep}`,
                                                    selectedRow.cards[
                                                        activeStep
                                                    ].category,
                                                )
                                            }
                                            clickable
                                            sx={{
                                                fontSize: '1.2rem',
                                                height: '38px',
                                                backgroundColor: selectedChips[
                                                    `${selectedRow.id}-${activeStep}`
                                                ]?.some(
                                                    (i) =>
                                                        i.split('.').pop() ===
                                                            chip || i === chip,
                                                )
                                                    ? '#1996a8'
                                                    : 'white',
                                                color: selectedChips[
                                                    `${selectedRow.id}-${activeStep}`
                                                ]?.some(
                                                    (i) =>
                                                        i.split('.').pop() ===
                                                            chip || i === chip,
                                                )
                                                    ? 'white'
                                                    : 'initial',
                                                '&:hover': {
                                                    backgroundColor:
                                                        selectedChips[
                                                            `${selectedRow.id}-${activeStep}`
                                                        ]?.some(
                                                            (i) =>
                                                                i
                                                                    .split('.')
                                                                    .pop() ===
                                                                    chip ||
                                                                i === chip,
                                                        )
                                                            ? '#147885'
                                                            : '#e0e0e0',
                                                },
                                            }}
                                        />
                                    ),
                                )}
                            </div>

                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={4}
                                label="Investigation Notes"
                                placeholder="Ran search X in system Y ..."
                                value={
                                    cardNotes[
                                        `${selectedRow.id}-${activeStep}`
                                    ] || ''
                                }
                                onChange={(e) => {
                                    setCardNotes((prev) => ({
                                        ...prev,
                                        [`${selectedRow.id}-${activeStep}`]:
                                            e.target.value,
                                    }))
                                }}
                                sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-input': {
                                        color: '#B5E7ED',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#B5E7ED',
                                        },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#B5E7ED',
                                        '&:hover': {
                                            borderColor: '#B5E7ED',
                                        },
                                    },
                                    '& .MuiOutlinedInput:before': {
                                        borderColor: 'white',
                                    },
                                }}
                                variant="outlined"
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '16px',
                                }}
                            >
                                <button
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                    style={{ padding: '8px' }}
                                >
                                    <KeyboardArrowLeft />
                                </button>
                                <button
                                    onClick={handleNext}
                                    disabled={
                                        activeStep ===
                                        selectedRow.cards.length - 1
                                    }
                                    style={{ padding: '8px' }}
                                >
                                    <KeyboardArrowRight />
                                </button>
                            </div>
                        </div>
                    )}
                </Box>
            </Modal>
            <GenerateReportModal
                open={pdfModalOpen}
                onClose={handleModalClose}
                generating={generatingPdf}
                onDownload={handlePdfDownload}
                error={pdfError}
            />
            <Box
                sx={{
                    position: 'fixed',
                    top: 100,
                    left: -45,
                    transform: 'rotate(-45deg)',
                    bgcolor: '#1996a8',
                    color: 'white',
                    padding: '5px 40px',
                    textAlign: 'center',
                    width: '200px',
                    boxShadow: 1,
                    zIndex: 1000,
                }}
            >
                <Typography component="h5" sx={{ fontWeight: 'bold' }}>
                    BETA
                </Typography>
            </Box>
        </Container>
    )
}

export default Playbook
