import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Alert,
} from '@mui/material'

const GenerateReportModal = ({
    open,
    onClose,
    generating: actuallyGenerating,
    onDownload,
    error
}) => {
    const [isBeepActive, setIsBeepActive] = useState(true)
    const [showGenerating, setShowGenerating] = useState(true)

    useEffect(() => {
        let animationInterval
        let minimumTimeoutId

        if (open) {
            setShowGenerating(true)
            setIsBeepActive(true) // Reset animation state

            // Start animation
            animationInterval = setInterval(() => {
                setIsBeepActive((prev) => !prev)
            }, 500)

            // Set minimum time for animation
            minimumTimeoutId = setTimeout(() => {
                if (!actuallyGenerating) {
                    setShowGenerating(false)
                }
            }, 3000)
        } else {
            // Reset states when modal closes
            setIsBeepActive(true)
            setShowGenerating(true)
        }

        return () => {
            if (animationInterval) clearInterval(animationInterval)
            if (minimumTimeoutId) clearTimeout(minimumTimeoutId)
        }
    }, [open, actuallyGenerating])

    useEffect(() => {
        let timeoutId
        if (!actuallyGenerating) {
            timeoutId = setTimeout(() => {
                setShowGenerating(false)
            }, 3000)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [actuallyGenerating])

    const beepBoop = () => {
        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: isBeepActive ? '#00A3BF' : '#808080',
                        transition: 'color 0.3s ease',
                    }}
                >
                    beep
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        color: isBeepActive ? '#808080' : '#00A3BF',
                        transition: 'color 0.3s ease',
                    }}
                >
                    boop
                </Typography>
            </Box>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={showGenerating ? undefined : onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Generate Report</DialogTitle>
            <DialogContent>
                {error ? (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            py: 4,
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="h5">
                            {showGenerating ? beepBoop() : 'Report ready!'}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={showGenerating && !error}
                >
                    {error ? 'Close' : 'Cancel'}
                </Button>
                {!error && (
                    <Button
                        onClick={onDownload}
                        disabled={showGenerating}
                        variant="contained"
                        sx={{
                            bgcolor: '#1996a8',
                            '&:hover': {
                                bgcolor: '#147885',
                            },
                            '&.Mui-disabled': {
                                bgcolor: '#e0e0e0',
                            },
                        }}
                    >
                        Download
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default GenerateReportModal