import React from 'react'

const AppBackground = ({ children }) => (
    <div
        style={{
            backgroundColor: '#00243A',
            backgroundImage: `
        linear-gradient(45deg, #1996a815 25%, transparent 25%),
        linear-gradient(-45deg, #1996a815 25%, transparent 25%)
      `,
            backgroundSize: '60px 60px',
            minHeight: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
        }}
    />
)
export default AppBackground
