import React from 'react'
import { Box, Container, Paper, Typography, Button, Link } from '@mui/material'

const PLAYBOOKS = [
    {
        title: 'Brute Force Authentication',
        location: 'bruteforce',
    },
    {
        title: 'Data Exfiltration',
        location: 'dataExfil',
    },
    {
        title: 'Lateral Movement',
        location: 'lateralMovement',
    },
    {
        title: 'Malware',
        location: 'malware',
    },
]

const Home = () => {
    return (
        <Container
            sx={{
                minHeight: '90vh',
                pt: 4,
                pb: 8,
                backgroundColor: '#031017',
            }}
        >
            <Paper sx={{ bgcolor: '#00243A', p: 4 }}>
                <Box sx={{ color: 'white' }}>
                    <Box sx={{ pt: 8, pb: 4, pl: 8, pr: 8 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                textAlign: 'center',
                                pb: 4,
                                fontWeight: 'bold',
                            }}
                        >
                            Studio
                        </Typography>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            SOC analysts' guide to cyber investigations
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            href="/about"
                            sx={{
                                '&:hover': {
                                    textDecoration: 'none',
                                    color: 'white',
                                },
                            }}
                        >
                            New to Studio? Start here
                        </Button>
                    </Box>
                    <Box sx={{ pt: 4, pb: 4, pl: 8, pr: 8 }}>
                        <Typography
                            variant="body1"
                            sx={{ textAlign: 'center' }}
                        >
                            Use the guides below to investigate, contextualize
                            and document your cyber alerts
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            border: '2px solid #1996a8',
                            borderRadius: 2,
                            m: 4,
                            mb: 16,
                            position: 'relative',
                            outline: 5,
                            outlineColor: '#1996a8',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                position: 'absolute',
                                top: '-24px',
                                left: '40px',
                                p: '0 10px',
                                backgroundColor: '#00243A', // Match your background
                                color: 'white',
                            }}
                        >
                            Alert Investigation Guides
                        </Typography>
                        <Box
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            {PLAYBOOKS.map((playbook) => (
                                <Box
                                    key={playbook.location}
                                    sx={{
                                        flex: '0 0 auto',
                                        flexBasis: 'calc(50% - 8px)', // For 2 buttons per row
                                        minWidth: 'calc(50% - 8px)', // Minimum width before wrapping
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        href={`/guides/${playbook.location}`}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: '#FBC9AE',
                                            color: 'black',
                                            '&:hover': {
                                                backgroundColor: '#F8AD86',
                                                color: 'black',
                                                boxShadow: 'none',
                                            },
                                        }}
                                    >
                                        {playbook.title}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ bgcolor: '#031017', p: 1, mr: 6, ml: 6 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'white',
                                textAlign: 'center',
                            }}
                        >
                            Looking for automated alert triage? Check out{' '}
                            <Link
                                href="https://salemcyber.com/solution"
                                target="_blank"
                                sx={{
                                    color: '#91DBE5',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        color: '#F8AD86',
                                    },
                                }}
                            >
                                Salem, AI Cyber Analyst
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Box
                sx={{
                    position: 'fixed',
                    top: 100,
                    left: -45,
                    transform: 'rotate(-45deg)',
                    bgcolor: '#1996a8',
                    color: 'white',
                    padding: '5px 40px',
                    textAlign: 'center',
                    width: '200px',
                    boxShadow: 1,
                    zIndex: 1000,
                }}
            >
                <Typography component="h5" sx={{ fontWeight: 'bold' }}>
                    BETA
                </Typography>
            </Box>
        </Container>
    )
}

export default Home
