import React, { useEffect } from 'react'
import {
    Box,
    Container,
    Paper,
    Typography,
    Button,
    Grid2,
    Link,
} from '@mui/material'
import SalemWorkflow from '../Images/SalemWorkflow.png'
import StudioWorkflow from '../Images/StudioWorkflow.png'

const About = () => {
    useEffect(() => {
        // Create script element
        if (
            !document.querySelector(
                'script[src="https://js.storylane.io/js/v2/storylane.js"]',
            )
        ) {
            // Create script element
            const script = document.createElement('script')
            script.src = 'https://js.storylane.io/js/v2/storylane.js'
            script.async = true

            // Add script to document
            document.body.appendChild(script)
        }
    }, [])
    return (
        <Container
            sx={{
                minHeight: '90vh',
                pt: 4,
                pb: 8,
                backgroundColor: '#031017',
            }}
        >
            <Paper sx={{ bgcolor: '#00243A', p: 4 }}>
                <Box sx={{ color: 'white' }}>
                    <Box sx={{ pt: 8, pb: 4, pl: 8, pr: 8 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                textAlign: 'center',
                                pb: 4,
                                fontWeight: 'bold',
                            }}
                        >
                            Get to know Studio
                        </Typography>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            a community project by Salem Cyber
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            href="/"
                            sx={{
                                '&:hover': {
                                    textDecoration: 'none',
                                    color: 'white',
                                },
                            }}
                        >
                            Jump to Investigation Guides
                        </Button>
                    </Box>
                    <Box sx={{ mt: 16 }}>
                        <div>
                            <div
                                className="sl-embed"
                                style={{
                                    position: 'relative',
                                    paddingBottom: 'calc(67.57% + 25px)',
                                    width: '100%',
                                    height: 0,
                                    transform: 'scale(1)',
                                }}
                            >
                                <iframe
                                    title="StudioDemo"
                                    loading="lazy"
                                    className="sl-demo"
                                    src="https://salem.storylane.io/demo/fdrrq28xipq7?embed=inline"
                                    name="sl-embed"
                                    allow="fullscreen"
                                    allowFullScreen
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: '1px solid rgba(63,95,172,0.35)',
                                        boxShadow:
                                            '0px 0px 18px rgba(26, 19, 72, 0.15)',
                                        borderRadius: '10px',
                                        boxSizing: 'border-box',
                                    }}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ mt: 16 }}>
                        <Typography variant="h5" sx={{ mb: 4 }}>
                            How does Studio compare to Salem, AI Cyber Analyst?
                        </Typography>
                        <Grid2 container spacing={2}>
                            <Grid2 size={6} sx={{ p: 4 }}>
                                <Typography
                                    variant="h4"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Studio
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Studio is a community resource to support
                                    and document your cyber investigations. It
                                    leverages the same award winning Salem AI
                                    engine to help you determine if your
                                    activity is a real threat.
                                </Typography>
                            </Grid2>
                            <Grid2 size={6} sx={{ p: 4 }}>
                                <Box
                                    component="img"
                                    src={StudioWorkflow}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2} sx={{ mt: 8 }}>
                            <Grid2 size={6} sx={{ p: 4 }}>
                                <Box
                                    component="img"
                                    src={SalemWorkflow}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Grid2>
                            <Grid2 size={6} sx={{ p: 4 }}>
                                <Typography
                                    variant="h4"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Salem
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Salem, AI cyber analyst, automates cyber
                                    investigations ensuring you're informed
                                    about validated threats as they occur. Salem
                                    understands how you investigate alerts in
                                    your environment, learns your business
                                    context and closes false postive alerts
                                    before they reach your desk.
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mt: 4,
                                        color: 'white',
                                    }}
                                >
                                    Looking for automated alert triage? Check
                                    out{' '}
                                    <Link
                                        href="https://salemcyber.com/solution"
                                        target="_blank"
                                        sx={{
                                            color: '#91DBE5',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'none',
                                                color: '#F8AD86',
                                            },
                                        }}
                                    >
                                        Salem, AI Cyber Analyst
                                    </Link>
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
            </Paper>
            <Box
                sx={{
                    position: 'fixed',
                    top: 100,
                    left: -45,
                    transform: 'rotate(-45deg)',
                    bgcolor: '#1996a8',
                    color: 'white',
                    padding: '5px 40px',
                    textAlign: 'center',
                    width: '200px',
                    boxShadow: 1,
                    zIndex: 1000,
                }}
            >
                <Typography component="h5" sx={{ fontWeight: 'bold' }}>
                    BETA
                </Typography>
            </Box>
        </Container>
    )
}

export default About
